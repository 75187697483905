'use client';

import type {FC} from 'react';
import {useCallback, useContext, useEffect} from 'react';
import {useState} from 'react';
import clsx from 'clsx';
import {useRouter} from 'next/navigation';
import {i18n} from 'utils/i18n';

import {Spinner} from '../spinner';

import {PreviewContext} from './provider';

export const PreviewBar: FC = () => {
	const router = useRouter();
	const [isExitingPreview, setIsExitingPreview] = useState(false);
	const {previewError} = useContext(PreviewContext);
	const [isIframe, setIsIframe] = useState(false);

	const handleClose = useCallback(async () => {
		setIsExitingPreview(true);

		try {
			await fetch('/api/draft-exit');
			router.push('/');
		} catch (error) {
			console.error('Failed to exit preview', error);
			setIsExitingPreview(false);
		}
	}, [router]);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setIsIframe(window.self !== window.top);
		}
	}, []);

	let showPreview = false;

	if (isIframe && previewError) {
		showPreview = true;
	} else if (isIframe && !previewError) {
		showPreview = false;
	} else if (!isIframe) {
		showPreview = true;
	}

	const title = previewError ? i18n.preview.error : i18n.preview.active;
	const description = previewError
		? i18n.preview.errorDescription
		: i18n.preview.activeDescription;

	return (
		<div
			className={clsx(
				previewError ? 'bg-[#DF3920]' : 'bg-preview',
				showPreview ? 'flex' : 'hidden',
				'flex-col',
				'items-center',
				'gap-2',
				'p-2',
				'px-4',
				'text-center',
				'!text-[14px]',
				'md:flex-row',
				'md:flex-wrap',
				'md:justify-between',
			)}
		>
			<strong className={clsx('block')}>{title}</strong>
			{description}
			{!isExitingPreview && (
				<button
					onClick={handleClose}
					className={clsx(
						'border',
						'px-4',
						'py-1',
						'hover:bg-black/40',
						'hover:text-white',
						'focus:bg-black/40',
						'focus:text-white',
					)}
				>
					{isExitingPreview ? i18n.preview.exiting : i18n.preview.exit}
				</button>
			)}
			{isExitingPreview && <Spinner />}
		</div>
	);
};

'use client';

import {type FC} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {SanityProjectDetailsProvider} from '@selvklart/sanity-next-image';
import {MotionConfig} from 'framer-motion';

import {PreviewContextProvider} from 'components/preview-bar/provider';

const queryClient = new QueryClient();

interface Props {
	children: React.ReactNode;
}

export const AppProvider: FC<Props> = ({children}) => {
	return (
		<QueryClientProvider client={queryClient}>
			<SanityProjectDetailsProvider
				projectDetails={{
					dataset: process.env.NEXT_PUBLIC_SANITY_DATASET ?? '',
					projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ?? '',
				}}
			>
				<MotionConfig reducedMotion="user">
					<PreviewContextProvider>{children}</PreviewContextProvider>
				</MotionConfig>
			</SanityProjectDetailsProvider>
		</QueryClientProvider>
	);
};

'use client';

import type {FC} from 'react';
import React, {useState} from 'react';
import {createContext} from 'react';

interface PreviewValue {
	previewError: Error | null;
	setPreviewError: React.Dispatch<React.SetStateAction<Error | null>>;
}

export const PreviewContext = createContext<PreviewValue>({
	previewError: null,
	setPreviewError: () => {
		// do nothing
	},
});

interface Props {
	children: React.ReactNode;
}

export const PreviewContextProvider: FC<Props> = ({children}) => {
	const [previewError, setPreviewError] = useState<Error | null>(null);

	return (
		<PreviewContext.Provider value={{previewError, setPreviewError}}>
			{children}
		</PreviewContext.Provider>
	);
};

export const i18n = {
	main: {
		kjelstad: 'Kjelstad',
		sanityStudio: 'Sanity Studio',
	},
	preview: {
		active: 'Forhåndsvisningsmodus aktivert.',
		activeDescription: 'Du kan nå se upublisert innhold.',
		error: 'Live forhåndsvisning kunne ikke aktiveres.',
		errorDescription:
			'Vanligvis betyr dette at nettleseren din blokkerte nettstedet fra å autentisere med Sanity. Prøv å deaktivere sporingsbeskyttelse på tvers av nettsteder for å la nettstedet autentisere seg.',
		exiting: 'Avslutter forhåndsvisning...',
		exit: 'Avslutt forhåndsvisningsmodus',
	},
} as const;
